import isologotipoC from "./isologotipoC.png";
import logovertical from "./logovertical.jpg";
import isologotipoN from "./isologotipoN.png";
import gartner from "./gartner.png";
import Presentacion from "./Presentacion-SF.png";
import Gpts1 from "./Gpts-customizeText.png";
import Gpts2 from "./Gpts-polidrama.png";
import Gpts3 from "./Gpts-papers.png";

export default {
  "grafica-gartner": gartner,
  "logo-celeste": isologotipoC,
  "logo-negro": isologotipoN,
  "logo-vertical": logovertical,
  "Presentacion-SF": Presentacion,
  gpt1: Gpts1,
  gpt2: Gpts2,
  gpt3: Gpts3,
};
