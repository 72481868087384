import React from "react";
import "../../App.css";
import images from "../../assets/img/images";

const PresentacionesyCharlas = () => {
  return (
    <div className="component-container">
      <div className="component-content animate__animated animate__fadeInLeft">
        <p className="content-header"> Presentaciones y Charlas:</p>
        <p className="content-text">
          Hemos preparado una charla/presentación denominada “Como la IA puede
          cambiar tu barrio”. En la charla analizamos las conclusiones de los
          últimos y más importantes papers sobre “tendencias en Inteligencia
          Artificial”. En todo el mundo se está estudiando el tema, intentando
          prever cuales serán las consecuencias.
          <br />
          Buscamos los últimos estudios más serios e intentamos decodificar las
          conclusiones, también nos aventuramos a adaptarlos para saber cómo
          impactará en la vida de los oyentes. Tratamos de cubrir todos los
          posibles escenarios para dar un pantallazo de que es lo último en el
          mundo sobre el tema, desde los más impactantes hasta los más
          incrédulos, desde los más benevolentes hasta los menos deseados,
          buscando dar luz a tantas preguntas que estás surgiendo, siempre desde
          la búsqueda de rigurosidad científica.
          <br />
          <br />
          <img
            style={{ maxWidth: "50vh", maxHeight: "auto" }}
            src={images["Presentacion-SF"]}
            alt="presentacion"
          ></img>
          <br />
          <br />
          Algunos de los papers que analizaremos son:
          <a
            href="https://arxiv.org/pdf/2303.10130.pdf
"
          >
            <br />
            <br />
            Estudio en Estados Unidos (Openai y Universidad de Pensilvania)
          </a>
          <br />
          <br />
          <a href="https://media.afi.es/webcorporativa/2023/03/Impacto-de-las-tecnologias-GPT-en-la-economia-espanola_Una-primera-aproximacion.pdf">
            Estudio en españa (AFI)
          </a>
          <a href="https://www.ippr.org/articles/transformed-by-ai">
            Nuevos trabajos a partir de la IA Foro Económico Mundial.
          </a>
          <br />
          <br />
          <a
            href="https://www.imf.org/es/Publications/fandd/issues/2023/12/Scenario-Planning-for-an-AGI-future-Anton-korinek
"
          >
            Estudio del Fondo Monetario Internacional, escenarios:
          </a>
          <br />
        </p>
      </div>
    </div>
  );
};

export default PresentacionesyCharlas;
